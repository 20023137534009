.edit-form[data-v-8266f94e] {
  margin: auto;
}
.edit-form .block-item[data-v-8266f94e] {
    width: 460px;
    margin: auto;
    margin-bottom: 22px;
}
.edit-form .block-item .el-select[data-v-8266f94e] {
      width: 100%;
}
@media (max-width: 768px) {
.edit-form .block-item[data-v-8266f94e] {
    width: auto;
}
.edit-form .block-time .el-form-item__content[data-v-8266f94e] {
    margin-left: 0 !important;
}
}
